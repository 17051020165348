import styled, { css } from 'styled-components';
import bgLeft from '../../../images/hero_polygon_left.svg';
import bgRight from '../../../images/hero_polygon_right.svg';
import { font } from '../../atoms/typography';

export default styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: ${props =>
    props.theme === 'dark' ? '#171E2A' : '#f9fbfe'};

  background-image: url(${props =>
    props.theme === 'dark'
      ? bgLeft
      : props.backgroundImage && props.backgroundImage}) ${props =>
  props.theme === 'dark' ? `, url(${bgRight})` : ''};
  ${props =>
    props.theme === 'dark' &&
    'background-position:left -200px, right calc(100% + 200px);background-repeat:no-repeat;'}
  ${props => props.theme !== 'dark' && 'background-size: cover;'}
  margin-bottom: ${props => props.className === 'bg-trans' && 0};
  .top-nav {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .nav-logo {
    width: 210px;
    height: 31px;
    @media (max-width: 375px) {
      justify-content: left !important;
      justify-self: left !important;
      margin-left: -116px;
    }
  }
  &.demo-container {
    background-color: #0015ca;
  }
  .hero-outer {
    height: 100%;
    position: relative;
    max-width: 1800px;
    margin: 0 auto;
  }
  .hero-container {
    padding-top: ${props => (!props.overflowImage ? '152px' : '232px')};
    padding-bottom: ${props =>
      props.bottomNav
        ? '0'
        : props.theme === 'dark'
        ? '96px'
        : !props.featuredImage
        ? '35px'
        : '50px'};
    padding-top: ${props => (props.form || props.theme === 'dark') && '96px'};
    text-align: ${props => !props.featuredImage && !props.form && 'center'};
    @media (max-width: 991.98px) {
      padding-top: ${props => (!props.featuredImage ? '152px' : '100px')};
      padding-bottom: ${props =>
        props.overflowImage && props.featuredImage ? '525px' : '0px'};
      ${props => (props.cIndex > 1 ? 'padding: 96px;' : '')}
    }
    @media (max-width: 575.98px) {
      ${props => (props.cIndex > 1 ? 'padding: 43px 0;' : '')}
    }
    @media (max-width: 768px) {
      padding-top: ${props => (!props.featuredImage ? '64px' : '100px')};
    }
    @media (max-width: 992px) {
      ${props => props.form && 'padding-top: 40px'};
    }
    .content-container {
      z-index: 10;
      max-width: ${props => !props.featuredImage && !props.form && '770px'};
    }
    h6 {
      color: var(--secondary-color);
      margin-bottom: 32px;
      text-transform: uppercase;
      @media (max-width: 768px) {
        margin-bottom: 16px;
      } 
    }
    h1, h2 {
      color: ${props => (props.lightNav || props.theme === 'dark') && '#fff'};
      margin-bottom: 32px;

      ${props =>
        props.form &&
        css`
          ${font('display', 'xl', '500')}
        `}
      
      @media (max-width: 768px) {
        max-width: 304px;
        margin: 0 auto;
        
        &.withSubhead {
          margin-bottom: 32px;
        }
        &.withOutSubhead {
          margin-top: 32px;
        }
      }
    }
    .subhead {
      color: ${props =>
        props.theme === 'dark'
          ? '#C2C6CC'
          : props.lightNav
          ? 'var(--light-color)'
          : 'var(--subtle-text-color)'};
      margin-bottom: ${props => (props.theme === 'dark' ? '48px' : '32px')};
      font-size: ${props => props.form && '22.5px'};
      
      a {
        color: white;
      }
    }
    .link-container {
      margin-bottom: ${props => (props.bottomNav ? '103px' : '32px')};
      display: flex;
      gap: 24px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      button { 
        width: max-content;
        letter-spacing: 1.4px;
      }
      .link {
        color: #fff;
        &::after {
          filter: invert(1);
          margin-left: 16px;
          right: initial;
        }
      }
      .btn-primary {
        background-color: ${props =>
          (props.lightNav || props.className === 'bg-trans') &&
          'var(--primary-color)'};
        color: ${props =>
          (props.lightNav || props.className === 'bg-trans') && '#fff'};
        &:hover {
          background-color: ${props =>
            (props.lightNav || props.className === 'bg-trans') &&
            '#fff'} !important;
          color: ${props =>
            (props.lightNav || props.className === 'bg-trans') &&
            'var(--text-color)'};
        }
      }
      .btn-outline-primary {
        border-color: ${props => props.lightNav && '#fff'};
        color: ${props => props.lightNav && '#fff'};
      }
    }
    .hero-button {
      
    }
    
    button:first-of-type {
      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
      @media (max-width: 375px) {
        margin-bottom: 0;
      }
    }
    .reviewsContainer {
      margin-bottom: 24px;
      ${props =>
        props.theme === 'dark' &&
        'justify-content: center;'} @media(max-width: 768 px) {
      margin-bottom: 16px;
    }
      span {
        margin-right: 7px;
        font-size: 18px;
        color: #EFC75E;
        line-height: 0;
        &:last-of-type {
          margin-right: 0;
        }
      }
      .social-caption {
        margin-left: 16px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 0;
      }
      .social-reviews {
        margin-left: 20px;
        line-height: 0;
        color: ${props =>
          props.theme === 'dark' ? '#FAFCFE' : 'var(--subtle-text-color)'};
        @media (max-width: 768px) {
          font-size: 14px;
          margin-left: 8px;
        }
      }
    }
    .company-icon {
      margin-right: 38px;
      margin-bottom: 8px;
      img {
        max-height: 20px !important;
      }
      &:last-of-type {
        margin-right: 0;
        ${props =>
          props.theme === 'dark' &&
          'filter: grayscale(1)brightness(0)invert(1);'}
        img {
          max-width: 200px;
        }
      }
      @media (max-width: 768px) {
        margin: 0 8px;
      }
    }
  }
  .wave {
    display: block;
    width: 100%;
    margin-top: -55px;
    height: auto;
    @media (max-width: 375px) {
      margin-top: -215px;
      width: 100%;
    }
  }
  /*-------------------------------------------------------------------------------
  *  Hero Illustration
  *   - Features micromovement on hover
  *   - Contains the following assets:
  *      + (1) iMac
  *      + (2) iPad
  *      + (3) Verifone
  *      + (4) Wrenches
  *      + (5) Dots
  *-------------------------------------------------------------------------------*/
  .homeHero__image {
    z-index: 1;
    position: absolute;
    width: 898px;
    top: 174px;
    right: -380px;
    @media screen and (min-width: 1200px) {
      top: 128px;
      transform: scale(1.2);
    }
    @media (max-width: 992px) {
      transform: none;
      top: 503px;
      left: 200px;
    }
    @media (max-width: 768px) {
      top: 585px;
      left: 170px;
    }
    @media (max-width: 476px) {
      top: 640px;
      left: 170px;
    }
    @media (max-width: 375px) {
      padding-top: 0px;
      top: 670px;
      left: 132px;
    }
    /* iMac */
    .homeHero__item--1 {
      z-index: 3;
      position: absolute;
      margin-top: 10px;
      margin-right: 15px;
      padding-right: 40px;
      width: 460px;
      transition: transform 1.2s;
      &:hover {
        transform: translate(10px, 0px);
      }
      @media (max-width: 375px) {
        margin-right: 29px;
        left: -34px;
        width: 380px;
      }
    }
    /* iPad */
    .homeHero__item--2 {
      position: absolute;
      z-index: 4;
      top: 130px;
      right: 150px;
      transition: transform 1.2s;
      &:hover {
        transform: translate(10px, 0px);
      }
      @media (max-width: 375px) {
        top: 110px;
        width: 50%;
        right: 265px;
      }
    }
    /* iPhone */
    .homeHero__item--3 {
      z-index: 5;
      position: absolute;
      top: 165px;
      width: 175px;
      transition: transform 1.2s;
      &:hover {
        transform: translate(10px, 0px);
      }
      @media (max-width: 375px) {
        top: 135px;
        left: .5px;
        width: 125px;
      }
    }
    /* Verifone */
    .homeHero__item--4 {
      position: absolute;
      margin-top: 145px;
      margin-left: -70px;
      width: 175px;
      z-index: 4;
      transition: transform 1.2s;
      &:hover {
        transform: translate(10px, 0px);
      }
      @media (max-width: 375px) {
        margin-top: 122px;
        margin-left: -57px;
        width: 145px;
      }
    }
    /* Wrenches */
    .homeHero__item--5 {
      z-index: 4;
      position: absolute;
      top: 250px;
      right: 270px;
      transition: transform 1.2s;
      &:hover {
        transform: translate(10px, 0px);
      }
      @media (max-width: 375px) {
        top: 190px;
        right: 360px;
        width: 117px;
      }
    }
    /* Dots */
    .homeHero__item--6 {
      position: absolute;
      margin-top: 20px;
      margin-left: -65px;
      z-index: 2;
      width: 80%;
      &:hover {
        animation: MoveUpDown 10s linear infinite;
      }
      @media (max-width: 375px) {
        margin-top: 20px;
        margin-left: -60px;
        width: 217px;
      }
    }
    /* Dots animation */
    @keyframes MoveUpDown {
      0%, 100% {
        margin-top: 20px;
      }
      50% {
        margin-top: 40px;
      }
    }
  }
  .bottom-nav {
    display: flex;
    justify-content: center;
    &-item {
      color: #fff;
      margin-right: 24px;
      &:last-of-type {
        margin-right: 0;
      }
      &:focus {
        outline: none;
      }
      cursor: pointer;
      padding: 10px 0;
      @media screen and (max-width: 568px) {
        margin: 0 2px;
      }
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 4px;
        transition: all 0.3s;
        background: #1c3fff;
        overflow: hidden;
        opacity: 0;
      }
      &:hover {
        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }
  }
`;
